import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import es from 'vuetify/lib/locale/es'
const opts = {
  lang: {
    locales: { es },
    current: "es"
  }
};
export default new Vuetify(opts);
