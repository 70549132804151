import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

//Filtro que formatea una numero con " . " cada tres digitos
Vue.filter('moneda', function (value) {
  if (!value) return 0
  value = (new Intl.NumberFormat('es', { style: 'currency', currency: 'COP' }).format(value)).split(',')
  return value[0]
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
