import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    token: "",
    user: {},
    credentials: {

    },
    role: null,
    stateLoad: false,
    currentClient: null,
    currentPrestamo: null,
    config: {
      
    }
  },
  mutations: {
    token(state, value) {
      state.token = value;
    },
    user(state, value) {
      state.user = value;
    },
    role(state, value) {
      state.role = value;
    },
    load(state, value) {
      state.stateLoad = value;
    },
    desactivar: (state) => {
      
      state.token = "";
      state.user = {};
      state.credentials = {};
      state.role = null;
    },
    setClient(state, value) {
      state.currentClient = value;
    },
    setPrestamo(state, value) {
      state.currentPrestamo = value;
    },
    setConfig(state, value) {
      state.config = value;
    }
    
  },
  actions: {
  },
  modules: {
  }
})
